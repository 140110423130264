import { Button, Image, Result } from "antd";
import React, { FC } from "react";
import { Link } from "react-router-dom";

export const NoMatchErrorPage: FC = () => {
    return (
        <Result
            status="404"
            title="404"
            subTitle="Tut uns leid, diese Seite existiert nicht."
            extra={<Link to={"/ticket"}><Button type="primary">Jetzt Ticket Kaufen</Button></Link>}
        />
    );
};