import { Spin } from 'antd';
import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { NoMatchErrorPage } from './error-pages/NoMatchErrorPage';
import './styles/App.scss';

// Layouts
const DefaultLayout = React.lazy(() => import("./layouts/DefaultLayout"));
const TicketsLayout = React.lazy(() => import("./layouts/TicketsLayout"));

// Views
const TicketSelection = React.lazy(() => import("./views/TicketSelection"));
const UserData = React.lazy(() => import("./views/UserData"));
const Payment = React.lazy(() => import("./views/Payment"));
const PaymentSuccess = React.lazy(() => import("./views/PaymentSuccess"));
const VerifyTicket = React.lazy(() => import("./views/VerifyTicket"));

const App: FC = () => (
  <Routes>
    <Route path='*' element={<NoMatchErrorPage />} />
    <Route
      path="/"
      element={
        <React.Suspense fallback={<Spin spinning fullscreen size="large" tip="Lädt..." />}>
          <DefaultLayout />
        </React.Suspense>
      }
    >
      <Route
        index
        element={
          <Navigate to="/ticket" />
        }
      />
      <Route
        path="ticket"
        element={
          <React.Suspense fallback={<Spin spinning fullscreen size="large" tip="Lädt..." />}>
            <TicketsLayout />
          </React.Suspense>
        }
      >
        <Route
          index
          element={
            <React.Suspense fallback={<Spin spinning />}>
              <TicketSelection />
            </React.Suspense>
          }
        />
        <Route
          path="select-tickets"
          element={
            <React.Suspense fallback={<Spin spinning />}>
              <TicketSelection />
            </React.Suspense>
          }
        />
        <Route
          path="user-info"
          element={
            <React.Suspense fallback={<Spin spinning />}>
              <UserData />
            </React.Suspense>
          }
        />
        <Route
          path="payment-info"
          element={
            <React.Suspense fallback={<Spin spinning />}>
              <Payment />
            </React.Suspense>
          }
        />
        <Route
          path="payment-ok"
          element={
            <React.Suspense fallback={<Spin spinning />}>
              <PaymentSuccess />
            </React.Suspense>
          }
        />
        <Route
          path="verify-ticket"
          element={
            <React.Suspense fallback={<Spin spinning />}>
              <VerifyTicket />
            </React.Suspense>
          }
        />
      </Route>
    </Route>
  </Routes>
);

export default App;
